// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import * as bootstrap from "bootstrap"
import "../scss/application"
import "jquery"
import "jquery-ui"
import Cookies from "js-cookie"
import Mousetrap from "mousetrap"
import InfiniteScroll from "infinite-scroll"


Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener('DOMContentLoaded', function (event) {
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  )
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  )
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
})

$(document).on('turbolinks:load', function () {

  if (!Cookies.get('show-non-stock')) {
    Cookies.set('show-non-stock', '0')
  }

  var non_stock_label = document.getElementById("toggle-non-stock-label");


  var showNonStock = Cookies.get('show-non-stock') == '1'

  if (non_stock_label != null) {
    if(showNonStock){
      non_stock_label.innerHTML = 'Show Stock Only';
    } else {
      non_stock_label.innerHTML = 'Show Non-Stock';
    }
  }

  $('#toggle-non-stock').prop('checked', showNonStock)

  $('#toggle-non-stock').on('change', function () {
    Cookies.set('toggle-non-stock-activated', '1');
    if ($(this).prop('checked')) {
      $('#non_stock').prop('checked', true);
      non_stock_label.innerHTML = 'Show Stock Only';

      Cookies.set('show-non-stock', '1');
    } else {
      $('#non_stock').prop('checked', false);
      non_stock_label.innerHTML = 'Show Non-Stock';
      Cookies.set('show-non-stock', '0');
    }

    if(this.getAttribute("prevent-callback") != "true"){
      $('#search_filter').submit();
    } else {
      location.reload();
    }

  });

  // prevents showing broken image tags
  // NOTE: this doesn't prevent a 404 in the console
  $('img').on('error', function () {
    $(this).hide()
  })

  Mousetrap.bind(['ctrl+/'], function () {
    var search = $('#nav-search')
    search.val('')
    search.trigger("focus")
    // return false to prevent default browser behavior
    // and stop event from bubbling
    return false
  })

  $('#main-search, #nav-search').each(function () {
    $(this).autocomplete({
      source: "/autocomplete",
      minLength: 2
    })
  })

  // This code could be used as a startpoint if we need to
  // maintain search terms between searches
  // $("#search_submit").submit( function(e) {
  //   var query = $(location).attr('search');
  //   var searchParams = new URLSearchParams(query);

  //   if (searchParams.has('term')) {
  //     e.preventDefault();
  //     var newTerm = document.getElementById('nav-search').value;
  //     searchParams.set("term",newTerm);
  //     window.location.search = searchParams.toString();
  //   }
  // });

  $("input[name='vendor_store[options]']").each(function() {
    if ($(this).is(':checked') && this.value == "week_delivery") {
      $("#weeklyDays input").removeAttr("disabled");
      return false
    } else {
      $("#weeklyDays input").attr("disabled", "disabled");
    }
  })

  $("input[name='vendor_store[options]']").click(function() {
    if (this.value == 'week_delivery') {
      $("#weeklyDays input").removeAttr("disabled");
    } else if(this.value == 'custom_delivery') {
      $("#weeklyDays input").attr("disabled", "disabled");
    } else if(this.value == 'no_delivery') {
      $("#weeklyDays input").attr("disabled", "disabled");
    }
  })

  $('.toggable-content').on('click', function(e) {
    var element = e.target;
    element.classList.toggle("shrink");
  })

  $('.form-check-input').on('click', function(e) {
    var element = e.target;
    var id = element.id;

    var subcategory_elements = $('.'+id)

    element.addEventListener("change", function(ev) {
        if (!ev.target.checked) {
          subcategory_elements.each(function() {
            $(this).prop("checked", false);
          });
        };
    });

    setTimeout(function() {
      $('#search_filter').submit();
    }, 200);
  });

  let elem = document.querySelector('.row-container');

  let infScroll = new InfiniteScroll( elem, {
    path: '#next_link',
    append: '.infinite-row',
    history: false,
    hideNav: '.page.next'
  });

  var retailType = $('#retail_program_type')
  var netType = $('#net_program_type')
  var docType = $('#document_type')

  $("#retail_program_document_type").on("change", function() {
    if (this.value == "retail_program") {
      retailType.val("RetailProgram");
    } else if (this.value == "net_program") {
      retailType.val("NetProgram");
    } else {
      retailType.val("");
    };
  });

  $("#net_program_document_type").on("change", function() {
    if (this.value == "retail_program") {
      netType.val("RetailProgram");
    } else if (this.value == "net_program") {
      netType.val("NetProgram");
    } else {
      netType.val("");
    };
  });

  $("#document_document_type").on("change", function() {
    if (this.value == "retail_program") {
      docType.val("RetailProgram");
    } else if (this.value == "net_program") {
      docType.val("NetProgram");
    } else {
      docType.val("");
    };
  });

})
